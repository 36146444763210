<template>
  <div class="inspectPointImport-wrapper">
    <div class="header">
      <v-button text="返回" @click="previous"></v-button>
    </div>
    <import-template :uploadUrl="uploadUrl" :downloadUrl="downloadUrl">
      <div slot="remark">
       <div>
          <p>以下皆为必填字段</p>
          <p>1、姓名：最多10位；</p>
          <p>2、手机号：11位数字；</p>
          <p>3、组织级别：平台、公司、项目；</p>
          <p>4、所属组织：必须是表中已有的组织名称；</p>
          <p>请在excel中按以上顺序填写数据</p>
          <p>最大支持10MB的excel文件，超过请拆分导入</p>
       </div>
      </div>
    </import-template>
  </div>
</template>
<script>
import { ImportTemplate } from 'components/bussiness/index'
import { importInternalURL } from './api'
export default {
  components: {
    ImportTemplate
  },
  data () {
    return {
      uploadUrl: importInternalURL,
      downloadUrl: '/static/excel/内部联系人导入模板.xlsx'
    }
  },
  created () {
    this.$setBreadcrumb('导入')
  },
  methods: {
    previous () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss" scoped>
  .inspectPointImport-wrapper {
    text-align: left;
    background: #fff;
  }
  .header {
    position: absolute;
    top: 20px;
    right: 20px;
    :not(:last-child) {
      margin-right: 8px;
    }
  }
</style>
